//Themes
$theme-primary: #cc3399;
$theme-blue: #007bff;
$theme-green: #28a745;
$theme-yellow: #ffc107;
$theme-orange: #eb9d20;
$theme-teal: #17a2b8;
$theme-red: #f45252;
$theme-grey: #ccc;
$theme-lightgrey: #f0f0f0;
$theme-lilac: #705fc6;
$theme-greenpastel: #4f864a;

//Typography
$font-family-sans-serif: "proxima-nova", "proxima nova", "helvetica neue", "helvetica", "arial", sans-serif;
$headings-font-family: "proxima-nova", "proxima nova", "helvetica neue", "helvetica", "arial", sans-serif;
$headings-color: #444;
