.v-toast {
    z-index: 9999999;
    opacity: 1;
    padding: 4px 10px;
}

.v-toast__item .v-toast__text {
    padding: 15px 20px;
}

.v-toast__item {
    min-height: 10px;
    margin: 5px 0;
    animation-duration: 250ms;
}