/*-------------------------------------------*\
   main.css
\*-------------------------------------------*/

//Main css
* {
  touch-action: manipulation;
}
body {
  color: #676767;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  height: 100%;
  background-color: #FFF;
}


.container {
  overflow-y: auto;
}

a {
    &:focus {
        outline: 0;
    }
    &:hover{
      text-decoration: none;
    }
}

a.disabled {
  pointer-events: none;
}

p {
    img {
        max-width: 100%;
        height: auto;
    }
}

main {
    margin-top: 57px;
    height: calc(100% - 114px);
    background-color: #FFF;
    padding: 10px 0;
    transition: margin-top 0.3s;
}

.no-top-nav{
  margin-top: 0px;
  height: calc(100% - 57px);
}

.only-top-nav{
  margin-top: 57px;
  height: calc(100% - 57px);
}

table{
  margin-bottom: 1rem;
}
td {
  padding: 5px 10px;
}

.container-scrollable {
  position: absolute;
  top: 0;
  width: 100% !important;
  max-width: 100% !important;
}

.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-fullscreen {
  margin-top: 0 !important;
  height: 100%;
}

.input-group * {
        width:auto;
}

.input-group .form-control {
    width:auto;
}

.justify-content-between {
  width:auto;
}

.narrow {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    padding: 0 (5px+$grid-gutter-width/2);
}

.nudge-up-center{
  margin-top: -40px;
  padding-top: 20px;
  padding-bottom: 20px;
}


.medium {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 (5px+$grid-gutter-width/2);
}
.rowfix {
  margin: 0 (-$grid-gutter-width/2) 0 (-$grid-gutter-width/2);
}
.large-row {
  margin: 0 -5px;
}

.row {
  clear: both;
}

.logo-block {
    text-align: center;
    margin-bottom: 20px;
}

.logo-large {
    width: 70px;
    margin-bottom: 20px;
}


//Typography

.spaced-caps {
    text-transform: uppercase;
    letter-spacing: 1px;
}

.subtitle {
    font-size: 1rem;
}

.dashboard-header {
  display: block;
  width: 100%;
    padding: 20px 6px 10px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #555;
}

.color-primary {
    color: $theme-primary;
}


@include media-breakpoint-up(md) {
  main{
    padding: 30px 0;
  }
}