//Footer css
footer {
  position:fixed;
  bottom: 0;
  width: 100%;
  z-index: 99999;
    background-color: #fff;
    height: 57px;
    padding: 14px 15px 10px 15px;
    color:rgba(0, 0, 0, 0.7);
    text-align: center;
    border-top: 1px solid #ececec;
    transition: transform .3s;
    &.footer-dark {
        background-color: rgba(60,60,60,1);
        color: #ccc;
        a {
          color: #ccc;

          &:hover {
            color: #ddd;
          }
        }
    }
}

.footer-fullscreen {
  display:none;
}
