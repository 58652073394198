.btn {
    padding: 0.47rem 0.75rem;
    font-size: 0.9rem !important;
    font-family: $headings-font-family;
    font-weight: 700;
    outline: none !important;
}

.btn:focus {
    box-shadow :none !important
}

.btn-mini {
    padding: 0.25rem 0.4rem 0.15rem 0.4rem;
    text-transform: none;
    font-size: 12px !important;
    font-family: $headings-font-family;
    font-weight: 700;
}

.btn-mini-label {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.5;
    vertical-align: middle;
    margin-bottom: 3px;
    padding: 0.4rem 0.4rem;
}

.btn-dashboard {
    display: block;
    outline: none !important;
    border-radius: 0.25rem;
    margin: 5px;
    padding: 1.2rem 2.0rem 1.2rem;
    font-family: $headings-font-family;
    font-size: 24px;
    color: #fff;
    transition: color 0.3s, background-color 0.3s, padding-left 0.3s;

    &:hover {
        padding: 1.2rem 1.5rem 1.2rem 2.5rem;
        text-decoration: none;
        color: #fff;
    }

    .btn-dashboard-text {
        margin-left: 10px;
    }
}
@mixin btn-dashboard-color($color) {
    background-color: $color;
    border: 1px solid $color;

    &:hover {
        background-color: darken($color, 10%);
        border: 1px solid darken($color, 10%);
    }

    &:active {
        background-color: darken($color, 50%);
        border: 1px solid darken($color, 50%);
    }
}

.btn-dashboard-primary {
    @include btn-dashboard-color($theme-primary);
}

.btn-dashboard-blue {
    @include btn-dashboard-color($theme-blue);
}

.btn-dashboard-green {
    @include btn-dashboard-color($theme-green);
}

.btn-dashboard-yellow {
    @include btn-dashboard-color($theme-yellow);
}

.btn-dashboard-orange {
    @include btn-dashboard-color($theme-orange);
}

.btn-dashboard-teal {
    @include btn-dashboard-color($theme-teal);
}

.btn-dashboard-red {
    @include btn-dashboard-color($theme-red);
}

.btn-dashboard-grey {
    @include btn-dashboard-color($theme-grey);
}
.btn-dashboard-lilac {
    @include btn-dashboard-color($theme-lilac);
}

.btn-dashboard-greenpastel {
    @include btn-dashboard-color($theme-greenpastel);
}
.btn-dashboard-black {
    @include btn-dashboard-color(lighten(#000, 20%));
}

.btn-dashboard-black {
    @include btn-dashboard-color(lighten(#000, 20%));
}

.btn-dashboard-questions-1 {
    @include btn-dashboard-color(#cb3fa1);
}

.btn-dashboard-questions-2 {
    @include btn-dashboard-color(#565AE0);
}

.btn-dashboard-questions-2a {
    @include btn-dashboard-color(#265cd1);
}

.btn-dashboard-questions-2b {
    @include btn-dashboard-color(#41aac5);
}

.btn-dashboard-questions-3 {
    @include btn-dashboard-color(#5295F7);
}

@mixin btn-notice-color($color) {
    &:hover {
        background-color: $color;
        border: 1px solid $color;
    }
}

.btn-notice-primary {
    @include btn-notice-color($theme-primary);
}

.btn-notice-blue {
    @include btn-notice-color($theme-blue);
}

.btn-notice-green {
    @include btn-notice-color($theme-green);
}

.btn-notice-yellow {
    @include btn-notice-color($theme-yellow);
}

.btn-notice-orange {
    @include btn-notice-color($theme-orange);
}

.btn-notice-teal {
    @include btn-notice-color($theme-teal);
}

.btn-notice-red {
    @include btn-notice-color($theme-red);
}

.btn-notice-grey {
    @include btn-notice-color($theme-grey);
}

.btn-outline-grey {
    color: darken($theme-grey, 40%);
    background-color: #fff;
    border: 1px solid $theme-grey;

    &:hover {
        background-color: darken($theme-grey, 40%);
        border: 1px solid darken($theme-grey, 40%);
        color: #fff;
    }
}

.dashboard-column {
    padding: 0 !important;
}

.btn-theme {
    color: #fff !important;
    background-color: $theme-primary;

    &:hover {
        background-color: darken($theme-primary, 10%);
    }
}

.btn-lightgrey {
    color: darken($theme-lightgrey, 30%) !important;
    background-color: $theme-lightgrey;

    &:hover {
        background-color: darken($theme-lightgrey, 2%);
    }
}

.btn-darkgrey {
    color: darken($theme-lightgrey, 90%) !important;
    background-color: $theme-lightgrey;

    &:hover {
        color: #fff !important;
        background-color: $theme-blue;
    }
}

.btn-link{
    font-weight: 400;
}

.btn-link-base {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;

    &:hover {
        color: rgba(0, 0, 0, 0.7);
    }
}

footer {
    .btn-link-base {
        display: inline;
    }
}

.btn-box {
  float: left;
    position: relative;
    display: block;
    width: 100%;
    border-radius: 0.25rem;
    margin: 5px 0 5px 0;
    padding: 1.0rem 1.5rem 1.2rem 5.9rem;
    border: 1px solid $theme-lightgrey;

    h3 {
        font-size: 1.4rem;
    }
    color: #333;

    .btn-box-date {
        position: absolute;
        bottom: 0.12rem;
        right: 0.4rem;
        color: #ccc;
        font-size: 12px;
    }

    &:hover {
        color: #fff;
        text-decoration: none;

        .btn-box-date {
            color: #fff;
        }
    }
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.btn-box-filled {
  @extend .btn-box;
  padding: 1.0rem 1.5rem 1.2rem 8.8rem;
  &:hover {
    color: #000;
  }
}

.btn-box-case {
  @extend .btn-box;
  padding: 1.0rem 1.5rem 1.0rem 1.5rem;
  &:hover {
    color: #000;
  }
}

@mixin btn-notice-type($type) {
    &:before {
        content: $type;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        font-size: 40px;
        color: #000;
        position: absolute;
        margin: -0.2rem 0 0 -4.1rem;
        z-index: 1;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }
}
.btn-notice-flag {
    @include btn-notice-type("\f024")
}
.btn-notice-star {
    @include btn-notice-type("\f005")
}
.btn-notice-book {
    @include btn-notice-type("\f02d")
}
.btn-notice-question-circle {
    @include btn-notice-type("\f059")
}
.btn-notice-info-circle {
    @include btn-notice-type("\f05a")
}
.btn-notice-exclamation-circle {
    @include btn-notice-type("\f06a")
}
.btn-notice-thumb-tack {
    @include btn-notice-type("\f08d")
}
.btn-notice-paper {
    @include btn-notice-type("\f1ea")
}
.btn-notice-building {
    @include btn-notice-type("\f0f7")
}
.btn-notice-user {
    @include btn-notice-type("\f2c0")
}
.btn-notice-user-circle {
    @include btn-notice-type("\f2bd")
}
.btn-notice-desktop {
    @include btn-notice-type("\f108")
}
.btn-notice-clock {
    @include btn-notice-type("\f017")
}

.btn-notice:hover:before {
    color: #fff;
}

.mce-btn {
    border-radius: 0.25rem;

    button {
        @extend .btn;
        padding: 0.47rem 0.75rem !important;
        text-transform: uppercase;
        font-size: 14px !important;
        letter-spacing: 1px;

        span {
            font-family: $font-family-sans-serif !important;
        }
    }
}

.btn-file {
    display: block;
    border-radius: 0.25rem;
    padding: 1.0rem 1.5rem 1.2rem 3.9rem;
    margin: 5px;
    border: 1px solid $theme-lightgrey;
    color: darken($theme-grey, 40%);
    hyphens: auto;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      color: #fff;
      background-color: $theme-blue;
      border: 1px solid $theme-blue;
    }
}

.btn-file:hover:before {
    color: #fff;
}
@mixin btn-file-type($type) {
    &:before {
        content: $type;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        font-size: 20px;
        color: #000;
        position: absolute;
        margin: -0.1rem 0 0 -2.4rem;
        z-index: 1;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }
}

.btn-file-none {
    @include btn-file-type("\f016")
}

.btn-file-pdf {
    @include btn-file-type("\f1c1")
}

.btn-file-document {
    @include btn-file-type("\f1c2")
}

.btn-file-spreadsheet {
    @include btn-file-type("\f1c3")
}

.btn-file-presentation {
    @include btn-file-type("\f1c4")
}

.btn-file-image {
    @include btn-file-type("\f1c5")
}

.btn-file-zip {
    @include btn-file-type("\f1c6")
}

.tag-display {
  display: inline-block;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 0.25rem;
  border: 1px solid $theme-grey;
  padding: 0.2rem 0.4rem;
  color: darken($theme-grey, 40%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  &:hover {
    color: #fff;
    background-color: darken($theme-grey, 40%);
    border: 1px solid darken($theme-grey, 40%);
  }
}

.tag-bar {
    margin-top: 15px;
    margin-bottom: -10px;
    .btn {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .btn-mini {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }

.btn-directory {
  margin-top: 10px;
  margin-right: 10px;
}

.theme-toggle {
  .active {
    background-color: $theme-primary !important;
    border: 1px solid $theme-primary !important;
    color: #fff !important;
  }
}

.red-toggle {
  .active {
    background-color: $theme-red !important;
    border: 1px solid $theme-red !important;
    color: #fff !important;
  }
}

.orange-toggle {
  .active {
    background-color: $theme-orange !important;
    border: 1px solid $theme-orange !important;
    color: #fff !important;
  }
}

.btn-directory:focus,.btn-directory.focus,.btn-directory:active {
    outline: none !important;
    box-shadow: none !important
}

.btn-mid-outline {
 border-right: 1px solid #fff;
 &:hover {
   border-right: 1px solid #666;
 }
}
.cat-list .input-group{
    .btn {
      display: flex;
      align-items: center;
    }
}

.btn-tool {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0;
    margin-bottom: 5px;
    -webkit-transition: background-color 0.3s, color 0.3s;
    transition: background-color 0.3s, color 0.3s;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;

    .btn-tool-icon {
        display: inline-block;
        padding: 0.8rem 0.9rem;
    }
    .btn-tool-text {
        display: none;
    }

    &:hover {
        background-color: #f0f0f0;
        color: #000000;
    }

    &:active {
        background-color: #353535;
        color: #ffffff;
    }
}

.btn-jumbotron {
    color: $theme-blue !important;
    background-color: #fff;

    &:hover {
        color: #fff !important;
        background-color: darken($theme-blue, 10%);
    }
}

.btn-highlight {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% + 1px);
    height: 5px;
    background-color: #000;
}

@include media-breakpoint-up(xl) {
    .btn-tool {
        .btn-tool-icon {
            display: inline-block;
            padding: 0.7rem 0.7rem;
        }
        .btn-tool-text {
            display: inline-block;
            padding: 0.7rem 0.7rem 0.7rem 0;
        }
    }
}

.btn-start {
    display: block;
    text-align: left;
    width: 100%;
    border-radius: 0.25rem;
    outline: none !important;
    margin: 5px;
    padding: 1.2rem 2.0rem 1.2rem;
    font-family: $headings-font-family;
    font-size: 24px;
    color: #fff;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
        padding: 1.2rem 1.5rem 1.2rem 2.5rem;
        text-decoration: none;
        color: #fff;
    }
}

.btn-filter{
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
    text-align: center;
    border-radius: 0.25rem;
    margin: 5px;
    padding: 0.8rem 0.8rem 0.8rem 0.8rem; 
    font-family: $headings-font-family;
    font-size: 15px;
    user-select: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    background-color: $theme-lightgrey;
    border: 1px solid $theme-lightgrey;
    outline: none !important;
    color: #333;

    .btn-filter-left{
        width: 40px;
        margin-right:10px;
    }

    .btn-filter-right{
        flex-grow: 1;
        text-align: left;
    }

    .icon {
        font-size: 25px;
    }

    &:hover {
        text-decoration: none;
        color: #1d1d1d;
        background-color: darken($theme-lightgrey, 5%);
        border: 1px solid darken($theme-lightgrey, 5%);
    }

    &:active {
        text-decoration: none;
        color: #fff;
        background-color: darken($theme-lightgrey, 60%);
        border: 1px solid darken($theme-lightgrey, 60%);
    }

    &.active {
        text-decoration: none;
        color: #fff;
        background-color: #41b883;
        border: 1px solid #41b883 !important;
    }

    .icon{
        display: block;
        margin-bottom: 5px;
    }
}

.btn-filter-column {
    width: 50%;
}

.col-btn-third {
    width: 33.33%;
    width: calc(100% / 3);
}

.btn-question-switch {
    display: block;
    width: calc(100% - 10px);
    text-align: center;
    border-radius: 0.25rem;
    margin: 5px;
    padding: 0.47rem 0.75rem;
    font-family: $headings-font-family;
    font-size: 15px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    background-color: $theme-lightgrey;
    border: 1px solid $theme-lightgrey;
    color: #333;

    &.active {
        text-decoration: none;
        color: #fff !important;
        background-color: $theme-green !important;
        border: 1px solid $theme-green !important;

        &:active {
            text-decoration: none;
            color: #fff;
            background-color: darken($theme-green, 30%);
            border: 1px solid darken($theme-green, 30%);
        }
    }

    &:hover {
        text-decoration: none;
        color: #1d1d1d;
        background-color: darken($theme-lightgrey, 10%);
        border: 1px solid darken($theme-lightgrey, 10%);
    }

}

.btn-choice {

    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: left;
    background-color: darken($theme-lightgrey, 5%);
    color: #333;

    &:hover {
        text-decoration: none;
        color: #1d1d1d;
        background-color: darken($theme-lightgrey, 20%);
    }

}

.btn-choice-selected {

    text-align: left;
    background-color: $theme-green !important;
    color: #fff !important;
    cursor: default !important;
    
}

.btn-disabled{
    cursor: default !important;
}

.btn-red {
    color: #fff;
    background-color: $theme-red;

    &:hover {
        color: #fff;
        background-color: darken($theme-red, 20%);
    }

    &:active {
        color: #fff;
        background-color: darken($theme-red, 40%);
    }
}

.btn-no-hover {
    border-color:  #777777 !important;
    color: #777777 !important;
    background-color: inherit !important;
    cursor: default;
}

.btn-rating{
    padding: 5px 5px 4px 5px;
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    border-radius: 3px;
    background-color: #d14e4e;

}

.btn-group-selector {
    display: block;
    width: 100%;
    margin-bottom: 3px;
}

.btn-permission{
    background-color: #e7e7e7;
    color: #272727;
    padding: 3px;
    margin: 0;
    width: 31px;
    height: 31px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border: 2px solid #fff;
    border-radius: 8px;
    &:hover{
        background-color: #c2c2c2;
    }
}

.btn-permission-active{
    background-color: #fff;
    color: #272727;
    padding: 3px;
    margin: 0;
    width: 31px;
    height: 31px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border: 2px solid #4da74a;
    border-radius: 8px;
    &:hover{
        background-color: #e7e7e7;
        color: #272727;
    }
}

.multiselect__select{
    height: 48px;
}
.multiselect__placeholder{
    font-size: 16px;
    font-weight: 400;
    padding-top: 4px;
    padding-left: 4px;
}
.multiselect__tags{
    min-height: 52px;
    padding: 10px 40px 0 10px;
}
.multiselect__input{
    padding: 6px 0 0 5px;
}
.multiselect__tag{
    padding: 7px 25px 7px 10px;
    font-family: "proxima-nova", "proxima nova", "helvetica neue", "helvetica", "arial", sans-serif;
    font-weight: 700;
}
.multiselect__tag-icon{
    line-height: 28px;
}
.multiselect__tag-icon:after{
    font-size: 18px;
}

.btn-panel-selector {
    width: 100%;
    text-align: left;
    color: #fff;
    border-top: 1px solid #636363;
    border-radius:  0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover{
        background-color: #265cd1;
        color: #fff;
    }
}