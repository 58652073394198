[v-cloak]
{
display:none;
}

.fadeheight-enter-active, .fadeheight-leave-active {
    transition: opacity .3s, max-height .3s, padding-top .3s, padding-bottom .3s;
  }
  .fadeheight-enter, .fadeheight-leave-to {
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
.fade-enter, .fade-leave-to {
    opacity: 0;
}


.fadein-enter-active {
    transition: opacity .3s;
  }
.fadein-enter{
    opacity: 0;
}

.fadezoomin-enter-active {
  transition: all .5s;
}
.fadezoomin-enter{
  opacity: 0;
  transform: scale(1.5);
}

.fadeinfixed-enter-active {
  transition: opacity .3s;
  top: 42px;
  position: absolute;
}
.fadeinfixed-enter{
  opacity: 0;
}

.faderight-enter-active, .faderight-leave-active {
  transition: opacity .5s, transform .5s;
}
.faderight-enter, .faderight-leave-to {
  opacity: 0;
  transform: translateX(100%) !important;
}

.fadeleft-enter-active, .fadeleft-leave-active {
  transition: opacity .5s, transform .5s;
}
.fadeleft-enter, .fadeleft-leave-to {
  opacity: 0;
  transform: translateX(-100%) !important;
}


.fadeinright-enter-active {
  transition: opacity .5s, transform .5s;
}
.fadeinright-leave-active {
  display: none;
}
.fadeinright-enter, .fadeinright-leave-to {
  opacity: 0;
  transform: translateX(20px) !important;
}

.fadeinleft-enter-active {
  transition: opacity .5s, transform .5s;
}
.fadeinleft-leave-active {
  display: none;
}
.fadeinleft-enter, .fadeinleft-leave-to {
  opacity: 0;
  transform: translateX(-20px) !important;
}

@include media-breakpoint-up(md) {
  .faderight-enter.fullscreen-hmode, .faderight-leave-to.fullscreen-hmode {
    opacity: 0;
    transform: translateX(-350px) !important;
  }
}

@include media-breakpoint-up(xl) {
  .faderight-enter.standard-hmode, .faderight-leave-to.standard-hmode {
    opacity: 0;
    transform: translateX(0) !important;
  }

  .faderight-enter.fullscreen-hmode, .faderight-leave-to.fullscreen-hmode {
    opacity: 0;
    transform: translateX(-350px) !important;
  }
}

.flip-list-move {
  transition: transform 0.5s;
  .add-form {
    transition: none !important;
    opacity: 0;
  }
}

.add-form {
  transition: opacity 0.5s !important;
}

.expand-enter-active,
.expand-leave-active {
  transition: opacity .3s, height .3s, padding-top .3s, padding-bottom .3s;
  overflow: hidden;
    will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.expand-enter,
.expand-leave-to {
  height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
    will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.fullfade {
  opacity: 0;
  &.loaded{
    -moz-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  }
}

.loaded {
  opacity: 1;
}

.rollright-enter-active, .rollright-leave-active {
  position: absolute;
  width: 100%;
  transition: opacity .5s, transform .5s;
}

.rollright-enter {
  opacity: 0;
  transform: translateX(100%) !important;
}

.rollright-enter-to {
  opacity: 100;
  transform: translateX(0%) !important;
}

.rollright-leave {
  opacity: 100;
  transform: translateX(0%) !important;
}

.rollright-leave-to {
  opacity: 0;
  transform: translateX(-100%) !important;
}



.rollleft-enter-active, .rollleft-leave-active {
  position: absolute;
  width: 100%;
  transition: opacity .5s, transform .5s;
}

.rollleft-enter {
  opacity: 0;
  transform: translateX(-100%) !important;
}

.rollleft-enter-to {
  opacity: 100;
  transform: translateX(0%) !important;
}

.rollleft-leave {
  opacity: 100;
  transform: translateX(0%) !important;
}

.rollleft-leave-to {
  opacity: 0;
  transform: translateX(100%) !important;
}
