.crosshair {
    cursor: crosshair;
}

.delete {
    cursor: crosshair;
}

.controls {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 40px;
    background-color: rgba(230, 230, 230, 0.5);
    transition: transform .3s;
    border-right: 1px solid #c5c5c5;
    &.showtray {
        transform: translateX(261px);
    }
}

.displayregion{
    border: 2px solid red !important;
}
.navigator{
    background-color: rgba(0, 0, 0, 0.6) !important;
}

@include media-breakpoint-up(md) {
    .controls {
        background-color: rgba(80, 80, 80, 0.8);
        border-right: 1px solid #636363;
    }
    .control-btn {
        color: #fff !important;
        border-bottom: 1px solid #636363 !important;
        &:hover{
            color: rgb(61, 61, 61) !important;
            background-color: #FFF !important;
            border-bottom: 1px solid #FFF !important;
        }
    }

    .expanding-menu {
        background-color: rgba(0, 0, 0, 0.3) !important;
    }
}

.expanding-menu {
    background-color: rgba(147, 147, 147, 0.5);
} 

.tray {
    position: absolute;
    overflow-y: auto;
    top: 0;
    left: 0;
    width: 261px;
    height: 100%;
    opacity: 0;
    border-right: 1px solid #686868;
    background-color: rgba(51, 51, 51, 0.8);
    pointer-events: none;
    transition: opacity .3s;
    z-index: 999999;
    &.showtray {
        opacity: 1;
        pointer-events: all;
    }

    .tray-annotation{
        display: block;
        color: #fff;
        padding: 9px 9px 10px 9px;
        border-bottom: 1px solid #636363;
        cursor: pointer;
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        user-select: none;
        transition: all .3s;
        &:hover {
            background-color: #535353;
        }
    }

    .tray-annotation-text{
        float: left;
        padding-left: 10px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        width: calc(100% - 52px) !important;
    }

    .tray-annotation-buttons {
        float: right;
        margin-right: -10px;
        margin-top: -10px;
        margin-bottom: -10px;
    }
    .tray-annotation-btn {
        float: right;
        margin: 0;
        padding: 9px 9px;
        font-size: 14px;
        color: #fff;
        border-radius: 0 !important;
        &:hover {
            background-color: #FFF;
            color: #2c2c2c;
        }
    }
    .tray-overview {
        margin: -9px -10px -10px -10px;
    }
    .tray-overview-btn {
        float: left;
        padding: 11px 11px 9px 11px;
        font-size: 12px !important;
        color: #fff;
        border-radius: 0 !important;
        border-right: 1px solid #636363;
        text-transform: uppercase;
        &.active {
            background-color: #46aa4b !important;
            color: #fff !important;
        }
        &:hover {
            background-color: #FFF;
            color: #2c2c2c;
        }
    }

    .tray-annotation-checkbox {
        float: left;
        width: 20px;
        height: 20px;
        border-radius: 4px;
    }

}

.control-btn {
    text-align: center;
    font-size: 15px;
    padding: 9px 10px 9px 10px;
    color: rgb(0, 0, 0);
    border-radius: 0;
    border-bottom: 1px solid #c5c5c5;

    &:hover{
        background-color: #FFF;
        border-bottom: 1px solid #FFF;
    }
    &.active{
        background-color: rgb(201, 201, 201);
    }
}

.base-message {
    bottom: 0;
}

.top-message {
    top:0;
}

.base-message, .top-message {
    width: calc(100% - 30px);
    margin: 15px;
    color: #fff;
    position: absolute;
    left: 0;
    text-align: center;
    pointer-events: none;

    .dynamic-message {
        display: inline-block;
        padding: 15px 25px;
        font-weight: 600;
        background-color: rgba(70, 70, 70, 0.9);
        border-radius: 4px;
    }

    .annotation-form, .panel-form {
        pointer-events: all;
        display: inline-block;
        padding: 0;
        font-weight: 600;
        background-color: rgba(70, 70, 70, 0.9);
        border-radius: 7px;
        border: 3px solid #cccccc;
        max-width: 800px;
        min-width: 150px;

        .annotation-form-content, .panel-form-content {
            display: block;
            width: 100%;
        }

        .check-message {
            text-align: left;
            padding: 10px;
        }

        .tutorial-message, .panel-message {
            padding: 10px;
            text-align: left;
            strong {
                color: #ec6d6d;
            }
            p {
                margin: 0;
            }
            a:hover {
                color: #789cda;
            }
        }

        .panel-selector {
            width: 100%;
            max-height: 100px;
        }

        .form-group {
            margin-bottom: 2px;
            .form-control {
                border: none;
                background-color: rgba(70, 70, 70, 0);
                color: rgb(221, 221, 221);
                font-weight: 600;
                &:focus {
                    box-shadow: none !important;
                }
                &:hover {
                    color: #fff;
                }
            }
        }
        .annotation-form-buttons-left {
            width: 42px;
            height: 44px;
            float: left;
            .btn:first-child{
                border-bottom-left-radius: 4px !important;
            }
        }
        .annotation-form-buttons-right {
            width: 42px;
            height: 44px;
            float: right;
            .btn:last-child{
                border-bottom-right-radius: 4px !important;
            }
        }
        .annotation-form-buttons-center-bar {
            float: left;
            width: calc(100% - 84px);
            height: 44px;
        }
        .annotation-form-buttons, .panel-form-buttons {
            display: block;
            width: 100%;
            font-size: 16px !important;
            margin: 0;
            border-top: 1px solid #636363;
            border-radius: 0 !important;
            min-width:300px;
            
            .btn {
                display: inline-block;
                font-size: 16px !important;
                padding: 9px 10px;
                border-radius: 0 !important;
                color: #FFF;
                &.btn-confirm {
                    background-color: #eb3737;
                    color: #FFF !important;
                }
                &.active {
                    background-color: #FFF;
                    color: #5e5e5e !important;
                    &:hover {
                        background-color: #FFF !important;
                    }
                }
                &:hover {
                    color: #333 !important;
                    background-color: rgba(255, 255, 255, 0.7);
                }
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
            }
        }

        .style-picker {
            margin-top: -10px;
            line-height: 0;
            text-align: left;
            margin-left: 10px;
            margin-bottom: 6px;
        }

        .annotation-style {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            margin: 4px;
            cursor: pointer;

            &.active {
                border: 2px solid #fff;
            }

            &:hover {
                opacity: 0.9;
            }
        }

        .floating-label{
            color: #FFF;
        }
    }

}

.annotation-style-0 {
    border: 2px solid #ffffff;
}

.annotation-style-editing {
    background-color: #FFF;
    border: 2px solid #ffbe0a;
}

.annotation-style-3 {
    background-color: #ffc400;
    border: 2px solid #ffc400;
    &.active{
        border: 2px solid #ffffff;;
    }
}

.annotation-style-2 {
    background-color: #ff0000;
    border: 2px solid #ff0000;
    &.active{
        border: 2px solid #ffffff;;
    }
}

.annotation-style-1 {
    background-color: #00FF00;
    border: 2px solid #00FF00;
    &.active{
        border: 2px solid #ffffff;;
    }
}

.choice-row {
    min-width: 150px;
}

.tutorial-choice-feedback {
    border-top: #636363 solid 1px;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: -5px;
    margin-left: -5px;
}

.viewer {
    height: 100%;
  }

  #container-slide {
    height: 100%;
    filter: contrast(1.1);
  }

  #minimap-resizable {
    position: absolute;
    display: block;
    top: 10px;
    right: 10px;
    border: 3px solid #222;
  }

  .viewer-loading {
      font-size: 90px;
      color: #686868;
      margin: 0 auto;
      text-align: center;

        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
  }

  .slide-viewer-title {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-weight: 600;
    padding: 8px 10px;
    border-radius: 4px;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.7);
    max-width: 300px;
    z-index: 999999;
    color: #b5b5b5;
  }