// Place all the styles related to the CaseGroups controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
.container-collections {
  padding: 0 !important;
  background-color: #fcfcfc;

  &.only-top-nav {
    .container-categories {
    height: calc(100% - 57px);
    }

    .container-content {
      height: calc(100% - 57px);
    }
    .container-display {
      height: calc(100% - 57px);
    }

    .container-filmstrip{
      height: calc(100% - 57px);
    }
  }

  &.main-fullscreen {

  .container-categories {
    height: 100%;
  }

  .container-content {
    height: 100%;
    &.stats-container {
      top: 42px; 
      height: calc(100% - 42px);
      &.menu-visible {
        top: 99px;
        height: calc(100% - 99px);
      }
    }
  }

  .container-content.fullheight {
    height: 100%;
  }

  .container-tools {
    height: 100%;
    &.stats-container {
      top: 42px; 
    }
  }

  .container-tools.fullheight {
    height: 100%;
  }

  }
}

.fullscreen-vmode {
  height: 100% !important;
}

.list-fullscreen-vmode {
  height: calc(100% - 42px) !important;
}

@include media-breakpoint-up(md) {
  .container-display.wide-hmode{
    z-index: 999999;
  }
  .card-folder{
    .folder-content{
      max-width: 70% !important;
    }
  }

  .stat-tools{
    top: 0 !important;
    &.menu-visible {
      top: 58px !important;
    }
  }

  .stats-container {
    top: 0!important;
    .container-resource{
    margin: 0 auto !important;
    }
  }

  .main-fullscreen.container-content {
    height: 100% !important;
  }

  .container-content {
    width: calc(100% - 400px) !important;
    left: 350px !important;
    pointer-events: all !important;
    &.stats-container {
      top: 0 !important;
      height: 100% !important;

      &.menu-visible{
        top:58px !important;
        height: calc(100% - 114px) !important;
      }

    }
  }

  .container-tools {
    right: 0 !important;
  }


  .container-categories {
    width: 350px !important;
    z-index: 990;
    &.active-md.standard-hmode {
      z-index: 99999 !important;
      pointer-events: all !important;
      opacity: 1;
    }
    &.active-md.wide-hmode {
      z-index: 99999 !important;
      pointer-events: all !important;
      opacity: 1;
    }
    //.cat-title {
      //width: 349px !important;
    //}
    .cat-title-text {
      //width: 280px;
      float: left;
    }

    &.fullscreen-hmode {
      transform: translateX(-350px) !important;
    }
    &.active.fullscreen-hmode {
      transform: translateX(0) !important;
    }
    z-index: 99999 !important;
  }

  .container-filmstrip {
    width: 350px !important;
    z-index: 999;
    &.active.fullscreen-hmode {
      pointer-events: all !important;
      transform: translateX(0);
    }
    &.active-md.standard-hmode {
      z-index: 99999 !important;
      pointer-events: all !important;
      transform: translateX(0);
    }
    &.active-md.wide-hmode {
      z-index: 99999 !important;
      pointer-events: all !important;
      transform: translateX(0);
    }
    &.standard-hmode {
      transform: translateX(350px);
    }
    &.fullscreen-hmode {
      transform: translateX(-350px);
      z-index: 99999 !important;
    }

    &.active.fullscreen-hmode {
      transform: translateX(0);
    }

  }

  .container-display {
    width: calc(100% - 350px) !important;
    left: 350px;
    pointer-events: all !important;
    opacity: 1 !important;
    &.active-md.standard-hmode {
      z-index: 99999 !important;
      pointer-events: all !important;
      opacity: 1;
    }
    &.standard-hmode {
      z-index: 999999;
    }
  

    &.fullscreen-hmode {
      width: 100% !important;
      left: 0 !important;
    }

    &.active.fullscreen-hmode {
      z-index: 999 !important;
    }
  }


  .jumbotron-content {
    
    .icon {
      font-size: 6em;
    }

    .attribute-badge {
      font-size: 14px;
      font-weight: 700;
    }

  }

}

@include media-breakpoint-up(xl) {
  .container-content {
    width: calc(100% - 650px) !important;
  }
  .container-tools {
    padding: 15px 15px !important;
    width: 300px !important;
  }

  .container-categories.standard-hmode {
    pointer-events: all !important;
    opacity: 1 !important;
    transform: translateX(0) !important;

    .highlight-blue {
      background-color: $theme-blue;
    }
  
    .highlight-green {
      background-color: $theme-green;
    }
  }

  .container-filmstrip.standard-hmode {
    pointer-events: all !important;
    left: 350px;
    transform: translateX(0) !important;
  }

  .container-display.standard-hmode {
    width: calc(100% - 700px) !important;
    pointer-events: all !important;
    left: 700px;
  }

  .container-categories.wide-hmode {
    pointer-events: all !important;
    opacity: 1 !important;
  }

  .container-filmstrip.wide-hmode {
    pointer-events: all !important;
    left: 0;
  }

  .container-display.wide-hmode {
    width: calc(100% - 350px) !important;
    pointer-events: all !important;
    left: 350px;
  }


}

.active {
  z-index: 99999 !important;
  pointer-events: all !important;
  &.send-to-back {
    z-index: 0 !important;
  }
}

.container-categories {
  background-color: #fff;
  height: calc(100% - 114px);
  overflow-y: auto;
  width: 100%;
  border-right: 1px solid #ececec;
  position: absolute;
  pointer-events: none;
  z-index: 0;
  -webkit-transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  
  opacity: 0;
  transform: translateX(-350px);

  &.active {
    opacity: 1 !important;
    transform: translateX(0);
  }

  .cat-title {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 11px 12px 9px 12px;
    color: #333;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 999;
    width: 100%;
    border-bottom: 1px solid #ececec;
  }

  .cat-title-text {
    width: 240px;
    float: left;
  }

  .cat-title-text-narrow {
    //width: 200px;
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }

  .cat-title-toolbar {
    float: right;
    margin: -10px -12px;
  }

  .cat-title-btn {
    font-size: 17px;
    padding: 8px 13px 8px 14px;
    border-left: 1px solid #ececec;
    color: #a0a0a0;
    cursor: pointer;
    float: right;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      background: #ececec;
      color: #333;
    }
    &:active {
      background-color: #353535;
      color: #f0f0f0;
    }
  }

  .cat-list {
    width: 100%;
    // overflow-y: auto;
    // overflow-x: hidden;
  }

  .cat-list-item {
    display: block;
    position:relative;
    cursor: pointer;
    background: #fff;
    color: #ccc;
    border-bottom: 1px solid #ececec;
    font-size: 12px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      background: #f0f0f0;
      color: #333;
    }
  }

  .cat-list-item-loading {
    background: #f0f0f0 !important;
    color: #333 !important;
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
  }

  .cat-list-loading {
    display: block;
    position:absolute;
    width: 100%;
    background: #fff;
    color: #ccc;
    border-bottom: 1px solid #ececec;
    font-size: 12px;
  }

  .cat-list-image {
    float: left;
    width: 69px;
    height: 69px;
    display: block;
    background-size: cover;
  }

  .cat-list-icon {
    display: block;
    font-size: 39px;
    padding: 15px 10px;
    line-height: 0;
  }

  .cat-list-content {
    float: left;
    padding: 12px 0;
    padding-right: 12px;
    color: #808080;
    width: calc(100% - 69px);

    .form-group {
      margin-bottom: 10px;
    }

    .tag-bar {
      margin-top: 5px;
      margin-bottom: -5px;
      padding: 0;
    }

    &.loading {
      padding: 21px 2px 0 2px;
    }
  }

  .loading {
    color: #929292 !important;
    h1, h2, h3, h4, h5, h6 {
      color: #929292 !important;
    }

  }

  .cat-list-highlight {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 6px;
  }

  .cat-list-title {
    width: 250px;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #333;
  }

  .cat-list-end-toolbar {
    text-align: center;
    padding: 18px 5px;
  }

}

.container-content {
  float: left;
  background-color: #fcfcfc;
  height: calc(100% - 114px);
  width: calc(100% - 50px);

  &.stats-container {
    top: 99px;
    height: calc(100% - 157px);
  }

  &.main-fullscreen {
    top: 42px !important;
    height: 100% !important;
  }

  position: absolute;
  overflow-y: auto;
  //pointer-events: none;
  -webkit-transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;

  h1, h2, h3, h4, h5, h6 {
   font-weight: 700;
  }

  p {
    padding: 0 2px;
  }
}

.container-tools {
  position: absolute;
  display:none;
  padding: 0;
  background-color: #fff;
  border-left: 1px solid #ececec;
  height: calc(100% - 114px);
  width: 50px;
  overflow-y: auto;
  transform: translate3d(0,0,0); 

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.container-tools.active{
  display:block;
  right: 0;
  z-index: 2000;
}

.container-padded {
  padding: 15px;
  padding-bottom: 0;
  border-bottom: 1px solid #ececec;
}

.filmstrip-content-max-height {
  max-height: 350px;
  overflow-y: scroll;
}

.container-filmstrip {
  background-color: #fff;
  height: calc(100% - 114px);
  width: 100%;
  position: absolute;
  border-right: 1px solid #ececec;
  pointer-events: none;
  z-index: 0;
  -webkit-transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;

  transform: translateX(120%);
  &.active{
    transform: translateX(0);
  }

  .filmstrip-content {
    padding: 15px;
  }

}

.filmstrip-header {
  margin: -0.75rem -1.25rem 0 -1.25rem;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 0.9rem;
  letter-spacing: 1px;
  color: #ebebeb;
  font-weight: 600;
  text-transform: uppercase;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.filmstrip-thumb {
  cursor: pointer;
  background-size: cover;
  height: 140px;
  &:hover {
    opacity: 0.8;
  }
}

.filmstrip-list {
  height: calc(100% - 41px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.filmstrip-title {
  font-size: 14px;
  font-weight: 600;
  padding: 11px 12px 9px 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #333;
  background-color: #fff;
  z-index: 999;
  width: 100%;
  border-bottom: 1px solid #ececec;

  .filmstrip-title-text {
    width: 250px;
    float: left;
  }
  .filmstrip-title-toolbar {
    width: 40px;
    float: left;
    margin: -10px 4px -10px -12px;
  }

  .filmstrip-title-btn {
    display: inline-block;
    font-size: 17px;
    padding: 8px 9px 8px 9px;
    color: #a0a0a0;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      background: #ececec;
      color: #333;
    }
  }
}

.header-emphasis{
  font-weight: 700;
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
}

.card {
  margin-bottom: 15px;

  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;

  .card-header {
    background-color: #fff;
    padding: 0.75rem 1.25rem 0.70rem 1.25rem;
  }

  .card-title {
    font-size: 0.9rem;
    letter-spacing: 1px;
    color: #333;
    font-weight: 400;
    text-transform: uppercase;
  }

  .card-body {
    .tag-bar {
      margin: -10px -10px -15px -10px;
    }
  }

  .card-footer {
    background-color: #fff !important;
  }

  &.card-btn {
    background-color: #f7f7f7;
    border: none !important;
    color: #222222;
    padding: 20px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    &:hover {
      background-color: #e4e4e4;
    }
  }

  &.play-form {
    background-color: #529b30;
    color: #fff;
    font-weight: 600;
    text-align: center;
    &:hover{
      background-color: #64b33f;
    }
  }

  &.description-form{
    background-color: #ececec;
    color: rgb(109, 109, 109);
    font-weight: 600;
    cursor: default;

  }

  &.slide-description-form{
    background-color: #ebebeb;
    color: rgb(109, 109, 109);
    cursor: default;
    &:hover{
      background-color: #ebebeb;
    }

  }

  &.card-bold {
    border: 3px solid #484b4e;
  }

  .card-btn-icon {
    margin: 0 auto;
    font-size: 2rem;
  }

  .card-btn-text {
    margin: 0 auto;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .card-toolbar {

    @extend .card-body;
    padding: 0;
    margin: 0;

    .btn:first-child{
      border-bottom-left-radius: 4px !important;
    }

    .card-toolbar-btn {
      display: inline-block;
      position: relative;
      color: #333;
      padding: 0.52rem 0.75rem 0.43rem 0.75rem;
      font-size: 12px !important;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-left: none !important;
      border-top: none !important;
      border-bottom: none !important;
      border-right: 1px solid #ececec;
      border-radius: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      &:hover {
        background-color: #ddd;
        color: #000;
      }
  
      &.active {
        background-color: #e6e6e6;
        color: #000;
      }

      &.disabled{
        background-color: #e6e6e6 !important;
        color: #aaaaaa !important;
        cursor: default;
      }
    }

  }

  .filmstrip-control {
    border-top: 1px solid #ececec;
    border-bottom: none;
  }

  .selector-control {
    padding-top: 15px;
    padding-bottom: 5px;
    
    .input-group-text {
      background-color: #fff !important;
    }

    .input-group-append .btn:hover {
      background-color: #535353;
      color: #fff;
    }

    .form-control:focus {
      box-shadow: none;
      border: 1px solid #ced4da !important;
    }
  }

  .selector-tag-header {
    padding-top: 5px;
    padding-bottom: 8px;
  }
  .selector-tag-buttons {
    margin-bottom: -10px;
    margin-top: -0;
  }
  .selector-slide-thumb {
    margin: -12px -15px -9px -15px;
    min-height: 100px;
    background-size: cover;
    &:hover {
      .selector-slide-header {
        opacity: 1;
      }
    }
  }

  .selector-slide-header {
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 0.9rem;
    letter-spacing: 1px;
    color: #ebebeb;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

      padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  }

  .selector-list-wrapper {
    padding-top: 15px;
  }
  .selector-list {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    .selector-list-item {
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      &:first-child {
        border-top: none !important;
      }
    }
    .selector-list-item-header {
      padding: 12px 15px;
      font-weight: 700;
    }
    .selector-list-item-button {
      cursor: pointer;
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      &:hover {
        background-color: #e6e6e6;
      }
      &:last-child {
        .selector-list-item-thumb {
          border-bottom-left-radius: 40x;
        }
        .selector-list-item-label {
          border-bottom-right-radius: 40x;
        }
      }
    }
    .selector-list-item-thumb {
      width: 50px;
      height: 50px;
      float: left;
    }
    .selector-list-item-label {
      padding: 12px 15px;
      width: calc(100% - 50px);
      height: 50px;
      float: left;
      overflow-x: scroll;
    }
  }

  .list-group-item-flush {
    padding: 12px 15px 9px 15px;
  }

  .list-group-item-equal {
    padding: 0.75rem 0.75rem;
  }

  .list-group-item-form {
    padding: 15px 15px 3px 15px;
  }

  .list-group-tag-bar {
    margin: 5px 0 -3px 0;
  }

  .block-data {
    background-color: #fdfdfd;
    p {
      margin: 0;
    }
  }
  
}

.card-editing {
  border-color: $theme-blue !important;
  box-shadow: 0 0 0 0.2rem $theme-blue;
}

.active-card .card {
  border-color: $theme-green !important;
  box-shadow: 0 0 0 0.2rem $theme-green;
}

.pointer-mode {
  cursor: pointer !important;
}

.bg-light {
  background-color: #fcfcfc !important;
}
.container-display {
  background-color: #222222;
  height: calc(100% - 114px);
  width: 100%;
  position: absolute;
  overflow: hidden !important;
  pointer-events: none;
  -webkit-transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  z-index: 10000;
  opacity: 0;

  &.active {
    opacity: 1 !important;
  }

  h1, h2, h3, h4, h5, h6 {
   font-weight: 700;
  }

  p {
    padding: 0 2px;
  }
}

.container-resource {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px 30px;
  .content {
    padding: 0 10px;
  }
}

.container-content.active{
  //pointer-events: all !important;
  opacity: 1;
  //z-index: 999;
}

.filmstrip-control {
  border-bottom: 1px solid #ececec;
  padding: 15px 15px 1px 15px;
}

.no-bottom-border {
  border-bottom: none !important;
}
.cat-control {

  border-bottom: 1px solid #ececec;

  .form-control {
    background-color: #fff;
    border: 1px solid #ced4da;
    color: #333;
  }
  .input-group{
    padding: 15px;
  }

  .input-group-text {
    background-color: #fff;
    border: 1px solid #ced4da;
  }

  .input-group-append .btn:hover {
    background-color: #535353;
    color: #fff;
  }

  .tag-bar {
    margin-top: 0;
    padding: 1px 15px 15px 15px;
  }

  .btn-outline-grey {
    color: #333;
    background-color: #fff;
    border: 1px solid #ced4da;
  }
  .btn-mid-outline {
    border-right: 1px solid #ced4da;
  }
}

.cat-toolbar {
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #ececec;
  .cat-toolbar-btn {
    display: inline-block;
    position: relative;
    color: #333;
    padding: 0.52rem 0.75rem 0.47rem 0.75rem;
    font-size: 12px !important;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-right: 1px solid #ececec;
    border-radius: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      background-color: #ddd;
      color: #000;
    }

    &:active {
      background-color: #353535;
      color: #f0f0f0;
    }

    &.active {
      background-color: #e6e6e6;
      color: #000;
    }
  }
}

.filmstrip-toolbar {
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #ececec;
  .filmstrip-toolbar-btn {
    display: inline-block;
    position: relative;
    color: #333;
    padding: 0.52rem 0.75rem 0.47rem 0.75rem;
    font-size: 12px !important;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-right: 1px solid #ececec;
    border-radius: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      background-color: #ddd;
      color: #000;
    }
    &:active {
      background-color: #353535;
      color: #f0f0f0;
    }

    &.active {
      background-color: #e6e6e6;
      color: #000;
    }
  }
}

.filmstrip-form {
  padding: 15px 15px;
  border-bottom: 1px solid #ececec;
}

.cat-list-form {
  padding: 15px 15px;
  border-bottom: 1px solid #ececec;
}

.textedit {
  background-color: #fff !important;
  color: $theme-blue !important;
}

.jumbotron-content {

  padding: 2rem 3rem 1.3rem 3rem;
  margin: 0 0 1.5rem 0;
  
  .icon {
    font-size: 2em;
    line-height: 0;
  }

  .attribute-large {
    margin-bottom: 0;
    word-wrap: break-word;
  }

  .attribute-badge {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 1rem;
    word-wrap: break-word;
  }
}

.break-word{
  word-wrap: break-word;
}

.jumbotron-blue {
  background-color: $theme-blue;
  color: #fff;
  h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }
}

.jumbotron-green {
  background-color: $theme-green;
  color: #fff;
  h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }
}

.jumbotron-orange {
  background-color: #d88a23;
  color: #fff;
  h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }
}

#close {
    cursor: pointer;
    margin-right: 10px;
}

.ti-new-tag-input-wrapper {
  padding: 4px 0 !important;
  font-size: 1em !important;
  margin: 4px !important;
}

.ti-new-tag-input {
  color: #495057 !important;
}

.ti-input {
  padding: 0.8rem 0.8rem 0.8rem 0.8rem !important;
  border-radius: 0.25rem !important;
  border: 1px solid #ced4da !important;
}
.ti-tag {
  @extend .btn;
  border-radius: 0.25rem !important;
  .ti-actions {
    font-size: 1em;
  }
}

.vue-tags-input {
  max-width: 100% !important;
}

.one-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
}

.start-bar {
  margin-top: 0;
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding: 10px 10px 10px 10px;

  .tag-bar {
    margin: -5px 0 0 0 !important;
    padding: 15px 5px 1px 5px !important;
    padding: 0;
  }

}


.section-header {
  display: inline-block;
  padding: 15px 15px 15px 15px;
  margin-bottom: -5px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;

}
.compact-section{
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-bottom: 20px !important;
}

.highlight-blue {
  background-color: $theme-blue;
}

.highlight-green {
  background-color: $theme-green;
}

.tox-tinymce {
  border-radius: 0.25rem !important;
}

.stat-tools{
  top: 42px;
  &.menu-visible {
    top: 99px;
  }
}

.stats-container {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  .container-resource{
    margin-right: 50px;
  }
}

.chart-container {
  margin-bottom: 90px;
}

.option-header {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}

.card-icon {
  font-size: 70px;
  text-align: center;
  width: 100%;
  color: #636363;
  -webkit-transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.card-marketing {
  background-color: #f0f0f0;
  border: none;
  &:hover{
    .card-icon{
      transform: scale(1.1, 1.1);
    }
  }
}
.marketing-card-text{
  height: 70px;
  color: #2c2c2c;
  cursor: default;
}

.switch-container{
  max-width: 340px;
  margin: 0 auto;
  .vue-switcher{
    margin-bottom: 0;
  }
}

.switch-container-bordered{
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}

.tag-switch-label{
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  margin-top: 1.3rem;
  color: #aaaaaa;
  &.active{
    color: #08922b;
  }
}
.switch-margin-top{
  margin-top: 1.8rem !important;
}

.question-loader{
  font-size: 40px;
  color: #c0c0c0;
}
.filmstrip-collapsed-placeholder {
  margin: 0 auto;
  padding: 30px;
  color: #ebebeb;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  cursor: default;
}

.filmstrip-placeholder {
  margin: 0 auto;
  padding: 30px;
  color: #ebebeb;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  cursor: default;
  transform: translateX(-20%)
}

.viewer-placeholder {
  height: 100%;
  background-color: #222222;
  margin: 0 auto;
  padding: 30px;
  color: #424242;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  cursor: default;
}

.card-folder {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &:hover{
    background-color: #f0f0f0;
  }

  &:active{
    background-color: #353535;
    color: #f0f0f0 !important;
  }

  .folder-icon-left{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 3;
    max-width: 60px;
    font-size: 24px;
  }
  .folder-content{
    flex-grow: 10;
    max-width: 70%;
    
    .folder-title{
      font-weight: 600;
    }
    .folder-info{
      font-size: 12px;
    }
  }
  .folder-icon-right{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    max-width: 60px;
  }
}

.card-top-border{
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.border-bottom-none{
  border-bottom: none !important;
}

.group-selector{
  display: block;
  max-height: 200px;
  overflow-y: scroll;
  width: 100%;
  margin-bottom: -3px;
}

@include media-breakpoint-up(sm) {
  .card-folder{
    .folder-content{
      max-width: 100%;
    }
  }
}

.slide-container {
  display: flex;
  padding: 12px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 4px;
  &:hover{
    background-color: #f0f0f0;
  }
  &:active {
    background-color: #353535;
    color: #f0f0f0;
  }

  &.selected {
    border-right: 6px solid #007bff;
  }

}

.slide-image {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 140px;
  width: 140px;
  height: 60px;
  border: 1px solid #b8b8b8;
  border-radius: 3px;
  margin-left: 15px;
  background-size: cover;
}

.slide-right-content {
  flex-grow: 1;
}

.slide-left-content {
  flex-grow: 1;
}

.slide-title {
  font-size: 18px;
  font-weight: 700;
}

.slide-description {
  font-size: 14px;
}

.card-element-border-bottom {
  border-bottom: 1px solid #ececec;
}

.card-element-border-top {
  border-top: 1px solid #ececec;
}

.permissions-toolbar {
  border: 1px solid #ccc;
  border-radius: 3px;
}

.permission{
  display: flex;
  padding: 9px;
  border-bottom: 1px solid #ccc;
  &:last-child{
    border-bottom: none;
  }
}

.permission-group{
  text-transform: none;
  font-weight: 700;
  font-size: 13px;
}

.permission-details{
  text-transform: none;
  font-weight: 300;
  font-size: 10px;
  color: #868686;
}

.permission-left{
  padding-left: 2px;
  padding-right: 4px;
  flex-grow: 1
}

.permission-right{
  flex-shrink: 0;
}

.lightgrey{
  color: #919191
}