// Top navigation
.bg-semi-white {
  border-bottom: 1px solid #ececec;
}
@include media-breakpoint-up(md) {

  .collapse.navbar-collapse {
    display: block !important;
    .navbar-nav {
      float: right;
    }
  }
}

.navbar {
  padding: 8px 12px 6px 12px;
  z-index: 999999;
  transform: translateY(0);
  transition: transform .3s;
  background-color: #fff;
}

.turbolinks-progress-bar {
  z-index: 9999999;
}

.navbar-fullscreen {
  transform: translateY(-60px);
}

.header-logo {
  height: 32px;
  width: 32px;
  display: block;
  float: left;
}
.header-logo-text {
  display: block;
  float: right;
  margin-left: 10px;
  font-family: $headings-font-family;
  font-size: 1.4rem;
  color: rgba(0,0,0,.9) !important;
}
.nav-item {
  em {
    font-style: normal;
    font-weight: 700;
  }
}
.navbar-brand {
  float: left;
}

// Toolbar
.toolbar {
  display: block;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0.25rem 0;
}

//Navbar toggler

.icon-bar {
	width: 22px;
	height: 2px;
	background-color: #B6B6B6;
	display: block;
	transition: all 0.2s;
	margin-top: 4px;
  &.top-bar {
    margin-top: 0;
  }
}

.navbar-toggler {
  border: none;
  background: transparent !important;
}

.nav-item {
  display: inline-block;
  text-align: center;
}
.nav-link {
  float: right;
}

.navbar-toggler .top-bar {
 transform: rotate(45deg);
 transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
 opacity: 0;
}

.navbar-toggler .bottom-bar {
 transform: rotate(-45deg);
 transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .top-bar {
 transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
 opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
 transform: rotate(0);
}

.navbar-toggler {
  border: none;
}

// Pagination
.pagination {
  text-align: center;
  a, .page.current, .page.gap {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    margin: 0 5px;
  }

  .page.current {
    font-weight: 700;
    margin-top: 2px;
  }
}
