select {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 1% 50%;
  	-moz-appearance: none;
  	-webkit-appearance: none;
  	appearance: none;
    padding-left: 20px !important;
}

input[readonly] {
  cursor: text;
}

.form-group label:not(.btn), .label-em {
  display: inline-block;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;

}

.form-group.last{
  margin-bottom: 0;
  label{
    margin-bottom: 0;
  }
}

.actions-spaced {
  border-top: 1px solid $theme-grey;
  text-align: center;
  margin-top: 0.6rem;
  padding-top: 0.6rem;
  .btn:not(.btn-block){
    margin-left: 5px;
    margin-bottom: 5px;
  }
}

input.error {
  border: 1px solid #f5c6cb !important;
}

.error {
  color: #721c24;
}

//Form select (to style bootstrap 4 element)
.form-select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

//Devise

#error_explanation {
  h2 {
    font-family: $font-family-sans-serif;
    font-size: 16px;
    color: rgb(198, 21, 21);
    margin: 20px 0;
  }
  ul{
    padding: 0;
    list-style-type:none;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: rgb(198, 21, 21);
  }
}

#login_message {
margin-bottom: 10px;
}

.field_with_errors {
  display: inline;
  color: rgb(198, 21, 21); 
}

.mce-textbox {
  @extend .form-control;
}

.tinymce {
width: 100% !important;
}

.tox{
  z-index: 99999;
}

.input-group-append {
  button, a {
    display: block;
    height: 38px;
  }
}

.input-group {
  input[type="text"]{
    float: left;
  }
  .input-group-append {
    float: left;
    border-radius: 0.25rem;
  }
}

// Floating labels

.floating-label-wrap {
  position: relative;
}

.floating-field-input {

  height: calc(3.4rem + 2px);
  padding: 1rem 0.9rem 0.2rem 0.9rem;

  & + .floating-label
   {
    position: absolute;
    top: 6px;
    left: 0;
    pointer-events: none;

    line-height: 1.5;
    font: inherit;
    padding: 1.2em 1.6em;
    height: 4em;

    transition: transform .25s, opacity .25s ease-in-out;
    transform-origin: 0 0;
    opacity: .5;
  }

  &::placeholder {
    color: transparent !important;
  }
  &::-ms-input-placeholder {
    color: transparent !important;
  }

  &:-ms-input-placeholder {
    color: transparent !important;
  }
}

.floating-field-input{
  &:focus {
    border-color: #ccc;
    box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.25);
  }

}

.minimise-label {
  transform: translate(0, -20%) scale(.8);
  opacity: .25;
}

.cat-list {
  .form-control:focus {
    box-shadow: none;
  }
}

.form-control{
  &:focus {
    border-color: #ccc;
    box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.25);
  }
}

.hidden-file {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}


.hidden-file-label {
  @extend .btn;
  display: block;
  width: calc(100% + 1px);
  background-color: #ffffff;
  border: 1px solid #ccc;
  &:hover {
    background-color: #d6d6d6;
  }
}

.editing {
  background-color: #f7f7f7;
}

.style-chooser {
  input[type="radio"] {
    display:none;
  }

  label {
    border: 1px solid #DDD;
    border-radius:5px;
    padding:10px;
    margin-top:10px;
    display:block !important;
    position:relative;
    color: #333;
    font-weight: bold;
    font-size: 15px;
  }
  
  label:hover {
    cursor:pointer;
    background-color:#EEE;
  }

  input[type="radio"]:checked + label {
    background-color:#006ec8;
    color: #fff;
  }
}

.date-selector{
  display: inline;
  width: inherit;
}

::placeholder{
  color:#929292 !important;
}