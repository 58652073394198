@font-face {
    font-family: 'proxima-nova';
    font-style: normal;
    font-weight: 400;
    src: local('Proxima Nova Regular'), local('Proxima Nova Regular'),
         url("../fonts/ProximaNova-Regular.woff") format('woff'), /* Modern Browsers */
  }

  @font-face {
    font-family: 'proxima-nova';
    font-style: bold;
    font-weight: 700;
    src: local('Proxima Nova Bold'), local('Proxima Nova Bold'),
         url("../fonts/ProximaNova-Bold.woff") format('woff'), /* Modern Browsers */
  }

  .text-muted-yellow{
     color: #f0a034;
  }