.container-feedback {
    margin-top: 0;
    height: 100%;
}
.quick-actions {
    margin-bottom: 10px;
  }

  .visual-chooser {
    input[type="radio"] {
      display:none;
    }
  
    label {
      background-color: #e3e3e3;
      color: #333;
      border-radius:5px;
      padding:10px;
      margin-top:10px;
      display:block !important;
      position:relative;
      font-size: 1em !important;
      font-weight: 700;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      outline: none !important;
    }
    
    label:hover {
      cursor:pointer;
      background-color: #bdbdbd;
    }
  
    input[type="radio"]:checked + label {
        &.green{
            background-color:#0db707;
            color: #fff;
        }
        &.blue{
            background-color:#009adb;
            color: #fff;
        }
        &.yellow{
            background-color:#e69f24;
            color: #fff;
        }
        &.orange{
            background-color:#e66f3b;
            color: #fff;
        }
        &.red{
            background-color:#cc3333;
            color: #fff;
        }
    }
  }

  .center{
    margin-left:auto;
    margin-right:auto;
    text-align: center;
  }

  .rating-chooser {

    font-size: 20px;

    input[type="radio"] {
      display:none;
    }

    label {
      background-color: #e3e3e3;
      color: #333;
      border-radius:5px;
      padding:10px;
      margin-top:10px;
      width: calc(20% - 5px);
      display:inline-block !important;
      position:relative;
      font-size: 2em !important;
      font-weight: 600;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      outline: none !important;
    }
    
    label:hover {
      cursor:pointer;
      background-color: #bdbdbd;
    }
  
    input[type="radio"]:checked + label {
        &.green{
            background-color:#0db707;
            color: #fff;
        }
        &.blue{
            background-color:#009adb;
            color: #fff;
        }
        &.yellow{
            background-color:#e69f24;
            color: #fff;
        }
        &.orange{
            background-color:#e66f3b;
            color: #fff;
        }
        &.red{
            background-color:#cc3333;
            color: #fff;
        }
    }
  }

  .feedback-textarea {
    font-size: 1.2em !important;
    border: 5px solid #ccc !important;
    border-radius: 9px;
    height: 100px !important;
    &:focus {
        border: 5px solid #0d0d0d !important;
        box-shadow: none;
    }

    &.ready {
        border: 5px solid #0069d9 !important;
        &:focus {
            border: 5px solid #0d0d0d !important;
            box-shadow: none;
        }
    }

    &.filled-green {
        border: 5px solid #05a13c !important;
    }
    &.filled-blue {
        border: 5px solid #009adb !important;
    }
    &.filled-yellow {
        border: 5px solid #e69f24 !important;
    }
    &.filled-orange {
        border: 5px solid #e66f3b !important;
    }
    &.filled-red {
        border: 5px solid #cc3333 !important;
    }

  }
  .btn-feedback {
    font-size: 1.2em !important;
  }

  .success-icon {
    font-size: 150px;
    color: #05a13c;
  }

  .thankyou{
    font-size: 1.2em;
  }

  .card-header-logo {
    font-size: 18px;
    float: left;
    width: 20px;
  }

  .card-header-text {
    margin-left: 10px;
    font-size: 19px;
    font-weight: 700;
    float: left;
    width: calc(100% - 40px);
  }

  @media screen and (min-width: 600px) {
    .card-header-logo {
      font-size: 25px;
      float: left;
      width: 30px;
    }
  
    .card-header-text {
      margin-left: 15px;
      font-size: 24px;
      font-weight: 700;
      float: left;
      width: calc(100% - 48px);
    }
  }

  a.card-blue {
    color: #fff !important;
    &:hover{
      color: #fff !important;
    }
  }

  .card-blue {
    border: 5px solid #0069d9;
    background-color: #0069d9;
    color: #fff !important;
    
    .card-header{
      background-color: #1888ff;
      border: 5px solid #1888ff;
      -webkit-transition: all 0.1s;
      transition: all 0.1s;
      color: #fff !important;
    }
    .list-group-item{
      background-color: #419af9;
      -webkit-transition: all 0.1s;
      transition: all 0.1s;
      color: #fff !important;
    }

    &:hover{
      .card-header{
        background-color: #0764c8;
        border: 5px solid #0764c8;
      }
      .list-group-item{
        background-color: #3d8ee5;
      }
    }

    &:active{
      .card-header{
        background-color: #002368 !important;
        border: 5px solid #002368 !important;
      }
      .list-group-item{
        background-color: #003192 !important;
      }
    } 
    
    .active{
      .card-header{
        background-color: #002368 !important;
        border: 5px solid #002368 !important;
      }
      .list-group-item{
        background-color: #003192 !important;
      }
    } 
    
  }

  .card-header-feedback-events{
    font-size: 20px;
    font-weight: 700;
    color: #fff !important;
    background-color: #484b4e !important;
    border-radius: 0 !important;
  }

  .list-group-open{
    background-color: #0069d9;
    color: #fff;
    font-weight: 700;
  }

  .list-group-open-later{
    background-color: #05a13c;
    color: #fff;
    font-weight: 700;
  }

  .list-group-closed{
    background-color: #cc3333;
    color: #fff;
    font-weight: 700;
  }

  .list-group-header{
    background-color: #0069d9;
    color: #fff;
    font-weight: 700;
  }